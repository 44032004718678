<template>
    <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
        <SubHeader title="결제하기"></SubHeader>

        <!-- 주문정보 -->
        <div class="rounded-3 bg-body shadow-200 mb-4 overflow-hidden">
            <h6 class="fw-bold p-3">주문정보</h6>

            <hr class="m-0 border-gray-relative-400">

            <div class="p-3">
                <div class="rounded-3 border border-gray-relative-300 px-3 form-control thick flex-between-center" @click="$refs.PaymentItemList.pp = true">
                    <span class="text-gray-relative-600">상품을 선택하세요</span>
                    <i class="fal fa-plus"></i>
                </div>
                <div class="mt-3" v-if="selected_item_list.length > 0">
                    <div class="flex-between-center my-1 mt-2 rounded-3 bg-gray-relative-100 ps-3 py-2 pe-2" v-for="(item,index) in selected_item_list" :key="index">
                        <div>
                            <div class="small">{{ item.name }}</div>
                            <div class="fs-px-14"><b>{{ item.price?item.price.toLocaleString():'N' }}</b> <small>원</small></div>
                        </div>
                        <i class="fas fa-times-circle small text-gray-relative-400 align-self-start ms-2" @click="Remove(index)"></i>
                    </div>
                </div>
            </div>

            <hr class="m-0 border-gray-relative-400">

            <div class="flex-between-center p-3">
                <span class="text-gray-relative-600">총 주문금액</span>
                <span><b class="fs-5">{{selected_item_list.reduce((a, b) => a + b.price, 0).toLocaleString()}}</b> 원</span>
            </div>
        </div>

        <div class="rounded-3 bg-body shadow-200 mb-4">
            <h6 class="fw-bold p-3">결제 방법</h6>
            <hr class="m-0 border-gray-relative-400">
            <div class="p-3">
                <div class="form-group">
                    <label class="small text-gray-relative-600">결제방식</label>
                    <select class="form-control" v-model="type">
                        <option value="" style="display:none">결제 방식을 선택해주세요.</option>
                        <option value="C">상품권</option>
                        <option value="P">캐쉬</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="rounded-3 bg-body shadow-200">
            <h6 class="fw-bold p-3">고객정보</h6>

            <hr class="m-0 border-gray-relative-400">

            <div class="p-3">
                <!-- 고객이름 -->
                <div class="form-group">
                    <label class="small text-gray-relative-600">고객 이름</label>
                    <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="고객 이름" v-model="name">
                </div>
                <!-- 고객전화번호 -->
                <div class="form-group mt-3">
                    <label class="small text-gray-relative-600">고객 전화번호</label>
                    <input class="form-control thick border-gray-relative-300 bg-body text-body fw-bold fs-6" type="text" placeholder="고객 전화번호" v-model="phone">
                </div>
            </div>
        </div>

        <div @click="CouponPaymentAdd()" class="rounded-3 btn thick btn-main w-100 mt-4" v-if="type=='C'">다음</div>
        <div @click="PointPaymentAdd()" class="rounded-3 btn thick btn-main w-100 mt-4" v-if="type=='P'">다음</div>

        
        <PaymentItemList ref="PaymentItemList" @Select="Select" :list="item_list" />


        <Loading v-if="loading"/>            
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import PaymentItemList from '@/components/popup/PaymentItemList.vue'
const CryptoJS = require("crypto-js");
import Loading from '@/components/common/Loading.vue'

export default {
    components: {
        SubHeader, PaymentItemList,Loading
    },
    data(){
        return{
            name : '',
            phone : '',
            type : '',
            selected_item_list: [],
            loading : false,
            item_list: [
                
            ]
        }
    },
    mounted(){
        this.GetItemList();
    },
    methods:{
        GetItemList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/shop/payment/GetItemList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.item_list = body.list;
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            );
        },
        Select(index){
            this.selected_item_list.push( this.item_list[index] );
            
        },
        Remove(index){
            this.selected_item_list.splice(index, 1)
        },
        PointPaymentAdd(){

            const item_list = this.selected_item_list;
            const name = this.name;
            const phone = this.phone;
            const type = this.type;

            if(item_list.length ==0){
                this.$alert("결제할 상품을 선택해주세요.");
                return false;
            }
            
            if(type ==''){
                this.$alert("결제 방식을 선택해주세요.");
                return false;
            }

            if(name ==''){
                this.$alert("고객 이름을 입력해주세요.");
                return false;
            }

            if(phone ==''){
                this.$alert("고객 휴대폰을 입력해주세요.");
                return false;
            }

            
            const body = {name,phone,item_list,type};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/shop/payment/PointPaymentAdd',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            const payment_code = body.code;
                            this.$router.push({path : `/point/payment/${payment_code}`})
                        }else if(res.data.code =="100"){
                            this.$alert("입력한 고객정보를 확인해주세요.");
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            );
        },
        CouponPaymentAdd(){

            const item_list = this.selected_item_list;
            const name = this.name;
            const phone = this.phone;
            const type = this.type;

            if(item_list.length ==0){
                this.$alert("결제할 상품을 선택해주세요.");
                return false;
            }
            
            if(type ==''){
                this.$alert("결제 방식을 선택해주세요.");
                return false;
            }

            if(name ==''){
                this.$alert("고객 이름을 입력해주세요.");
                return false;
            }

            if(phone ==''){
                this.$alert("고객 휴대폰을 입력해주세요.");
                return false;
            }

            
            const body = {name,phone,item_list,type};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/shop/payment/CouponPaymentAdd',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            const payment_code = body.code;

                            this.$router.push({path : `/coupon/payment/${payment_code}`})
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            );
        }
    }
}
</script>

<style>

</style>